<script>
import api from "@/api";
import Loader from "vue-element-loading";
import VueSelect from "vue-select";

export default {
  name: "",
  components: {
    Loader,
    VueSelect,
  },
  data() {
    return {
      loading: true,

      payment_policies: [],
      edit_id: null,
      edit_no_limit: null,
      edit_limit_type: null,
      edit_count: null,
      edit_active: null,

      new_name: null,
      new_no_limit: false,
      new_limit_type: null,
      new_count: null,
      new_active: true,

      limit_types: [],
      show_add_new: 0,
    };
  },
  methods: {
    getUser(pp) {
      if (pp.user_obj) {
        return pp.user_obj;
      }
      return pp.group_obj;
    },
    getPaymentPolicies() {
      return api.axios.get(this.url).then((response) => {
        var d = response.data;
        if (d.results) {
          d = d.results;
        }
        this.payment_policies = d;
      });
    },
    start_edit(pp) {
      this.edit_id = pp.id;
      this.edit_no_limit = pp.no_limit;
      this.edit_limit_type = pp.limit_type;
      this.edit_count = pp.count;
      this.edit_active = pp.active;
    },
    saveChanges() {
      var d = {
        no_limit: this.edit_no_limit,
        limit_type: this.edit_limit_type,
        count: this.edit_count,
        active: this.edit_active,
      };
      const url = this.url + this.edit_id + "/";
      api.axios.patch(url, d).then(() => {
        this.edit_id = null;
        this.edit_no_limit = null;
        this.edit_limit_type = null;
        this.edit_count = null;
        this.edit_active = null;
        this.getPaymentPolicies();
      });
    },
    deleteId(id) {
      const url = this.url + id + "/";
      api.axios.delete(url).then(() => {
        alert("Success!");
        this.getPaymentPolicies();
      });
    },
    addNew() {
      var d = {
        user: this.new_name,
        no_limit: this.new_no_limit,
        limit_type: this.new_limit_type,
        count: this.new_count,
        active: this.new_active,
      };
      api.axios.post(this.url, d).then(() => {
        this.show_add_new = 0;
        this.getPaymentPolicies();
        this.new_name = null;
        this.new_no_limit = null;
        this.new_limit_type = null;
        this.new_count = null;
        this.new_active = null;
      });
    },
    getLimits() {
      var url = this.url + "limit_types/";
      return api.axios.get(url).then((response) => {
        this.limit_types.splice(0);
        this.limit_types = response.data;
      });
    },
    setClient(data) {
      if (data && data.id) {
        this.new_name = data.id;
      } else {
        this.new_name = null;
      }
    },
    setLimitTypes(data) {
      if (data && data.id) {
        this.new_limit_type = data.id;
      } else {
        this.new_limit_type = null;
      }
    },
  },
  computed: {
    clients() {
      return this.$store.state.Manager.clients;
    },
    url() {
      return "/api/payment-policies/";
    },
  },
  created() {
    const promises = [];
    promises.push(this.$store.dispatch("Manager/loadClients"));
    promises.push(this.getPaymentPolicies());
    promises.push(this.getLimits());
    Promise.all(promises)
      .then(() => {
        this.loading = false;
      })
      .catch((err) => {
        console.error(err);
        alert("Error loading data. Please refresh.");
      });
  },
};
</script>

<template>
  <div id="PaymentPolicies" class="col">
    <Loader :active="loading" />

    <div class="row">
      <div class="col">
        <h3>Payment Policies</h3>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <p>
          By default, each rehearsal space uses the default option chosen under
          settings when a new booking is made. i.e. to have the booking show up
          on the calendar or to have the admin first confirm it for that to
          happen.
        </p>
        <p>
          Payment Policies allow you to customize this option and have different
          policies for different clients.
        </p>
        <p>
          You can allow one group to make unlimited bookings, or to only have
          till 4 unpaid sessions, or to have a maximum balance of 10,000/=
          before it disallows bookings.
        </p>
      </div>
    </div>

    <div class="row" id="add_new_button_row">
      <div class="col text-center">
        <button class="btn btn-primary" @click="show_add_new = 1">
          Add New
        </button>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Email</th>
            <th>No Limits?</th>
            <th>Limit Amount/Count</th>
            <th>Limit Type</th>
            <th>Is Active</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="show_add_new">
            <td>
              <VueSelect :options="clients" label="email" @input="setClient" />
            </td>
            <td><input type="checkbox" v-model="new_no_limit" /></td>
            <template v-if="new_no_limit">
              <td>---</td>
            </template>
            <template v-else>
              <td><input type="text" v-model="new_count" /></td>
            </template>
            <td>
              <VueSelect
                :options="limit_types"
                label="name"
                @input="setLimitTypes"
              />
            </td>
            <td>
              <input type="checkbox" v-model="new_active" />
            </td>
            <td>
              <button class="btn btn-success" @click="addNew">
                Add
                <i class="fas fa-plus"></i>
              </button>
            </td>
          </tr>

          <template v-if="payment_policies.length > 0">
            <template v-for="pp in payment_policies">
              <tr :class="edit_id == pp.id ? 'bg-warning' : ''" :key="pp.id">
                <td>
                  {{ getUser(pp).name }}
                </td>
                <template v-if="edit_id == pp.id">
                  <td><input type="checkbox" v-model="edit_no_limit" /></td>
                  <template v-if="edit_no_limit">
                    <td>---</td>
                    <td>---</td>
                  </template>
                  <template v-else>
                    <td>
                      <input
                        class="form-control"
                        type="text"
                        v-model="edit_count"
                      />
                    </td>
                    <td>
                      <select v-model="edit_limit_type">
                        <template v-for="lt in limit_types">
                          <option :value="lt.short" :key="lt.short">
                            {{ lt.full }}
                          </option>
                        </template>
                      </select>
                    </td>
                  </template>
                  <td>
                    <input type="checkbox" v-model="edit_active" />
                  </td>
                  <td>
                    <button class="btn btn-success" @click="saveChanges">
                      Save <i class="fas fa-save"></i>
                    </button>
                  </td>
                </template>
                <template v-else>
                  <td>
                    <div v-if="pp.no_limit" class="text-danger">
                      Unlimited
                      <i class="fas fa-check"> </i>
                    </div>
                    <div v-else class="text-success">
                      Is Limited
                      <i class="fas fa-times"> </i>
                    </div>
                  </td>
                  <td>
                    <template v-if="!pp.no_limit">
                      {{ pp.count }}
                    </template>
                    <template v-else> --- </template>
                  </td>
                  <td>
                    <template v-if="!pp.no_limit">
                      {{ pp.limit_type_full }}
                    </template>
                    <template v-else> --- </template>
                  </td>
                  <td>
                    <i class="fas fa-check" v-if="pp.active"> </i>
                    <i class="fas fa-times" v-else> </i>
                  </td>
                  <td>
                    <button class="btn btn-primary" @click="start_edit(pp)">
                      Edit <i class="fas fa-pen"></i>
                    </button>
                    <button class="btn btn-danger" @click="deleteId(pp.id)">
                      Delete <i class="fas fa-trash"></i>
                    </button>
                  </td>
                </template>
              </tr>
            </template>
          </template>
          <template v-else>
            <tr>
              <td colspan="20" class="text-center">No Policies to Show.</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style lang="scss">
#PaymentPolicies {
  margin-top: 10px;
}
#add_new_button_row {
  margin-bottom: 10px;
}
</style>
